<template>
  <div class="service-form-part">
    <ServiceTypeField
      :choices="serviceTypes"
      v-on="$listeners"
      @result="setServiceForType($event.value[0])"
    />

    <ServiceField
      ref="service"
      hidden
      v-on="$listeners"
    />
  </div>
</template>

<script>
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import ServiceTypeField from 'chimera/all/components/form/fields/serviceType/ServiceTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'
import { accountancyConsumerNL, accountancyCorporateNL } from 'chimera/accountancy/service'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceField,
    ServiceTypeField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      serviceTypes: [
        new Selectable('Particulier', 'Particulier', accountancyConsumerNL.id),
        new Selectable('Zakelijk', 'Zakelijk', accountancyCorporateNL.id)
      ]
    }
  },

  methods: {
    /**
     * @param {object}  type
     */
    setServiceForType (type) {
      if (!type) {
        return
      }

      const serviceType = this.serviceTypes.find(serviceType => serviceType.id === type.id)
      this.$refs.service.setValue(serviceType.value)
    }
  }
}
</script>
